body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FDE2F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #2A2F4F;
  text-align: center;
  font-style: italic;
}

p {
  text-align: center;
  font-size: xx-large;
}

button {
  border: 5px;
  outline: 0;
  cursor: pointer;
  color: white;
  background-color: #2A2F4F;
  /* box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(84 105 212) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px; */
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  min-height: 30px;
  transition: background-color .24s,box-shadow .24s;
  margin-left: 5px;
}

button:active {
	background: #505a97;
}

#bar_input {
  position: relative;
  border: 0;
  outline: 0;
  color: rgb(60, 66, 87);
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 4px 8px;
  min-height: 28px;
  vertical-align: middle;
  transition: background-color .24s,box-shadow .24s;
  transition-property: background-color, box-shadow;
  transition-duration: 0.24s, 0.24s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}

.copy-text {
  /* text-align: center; */
  /* margin-top: 100px; */
  display: absolute;
}

.copy-text button {
  margin-top: 30px;
  border-radius: 8px;
}

button:hover {
  background-color: #465082;
  /* color: #2A2F4F; */
}

img {
  max-width: 50%;
}

.btn {
  max-width: 29px;
  border: none;
  position: absolute;
}

.copy {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

#go_button {
  margin-left: 10px;
  margin-top: 5px;
}

.copy-text button:before {
	content: "Copied";
	position: absolute;
	top: -45px;
	right: 0px;
	background: #5c81dc;
	padding: 8px 10px;
	border-radius: 20px;
	font-size: 15px;
	display: none;
}

.copy-text.active button:before,
.copy-text.active button:after {
	display: block;
}

emj {
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-wrapper {
  display: absolute;
  margin-top: 10px;
}

cr {
  font-size: x-small;
  position: fixed;
  bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

#go_2_button {
  margin-left: 10px;
  margin-top: 5px;
  opacity: 0;
}

#copy_button {
  opacity: 1;
}

#copy_2_button {
  opacity: 0;
}

@media only screen and (min-width: 1200px) {
  #go_1_button:hover {
    opacity: 0;
  }
  
  #go_2_button:hover {
    opacity: 1;
  }
  
  #copy_button:hover {
    opacity: 0;
  }
  
  #copy_2_button:hover {
    opacity: 1;
  }
}
